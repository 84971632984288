/* Polyfills */
//import "core-js/stable";
//import "regenerator-runtime/runtime";
//import {Promise} from "es6-promise";

/* JS/Typescript */
import Main from "./ts/main";

const polyfills = [];

if (!window.fetch) {
    console.log("Loading fetch polyfill");
    // @ts-ignore
    polyfills.push(import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch'))
}

Promise.all(polyfills)
    .then(() => {
        document.addEventListener("DOMContentLoaded", function (event) {
            Main();

            // Codecentrics Cookie bar
            //import(/* webpackChunkName: "scriptjs" */ 'scriptjs').then((s: $script) => {
            // @ts-ignore
            //window.usercentrics = {
            //   settingsId: 'QSv6nkcq'
            //};
            //s.get('https://app.usercentrics.eu/latest/main.js', () => {
            //});
            //});
        });
    })
    .catch((error: any) => {
        console.error('Failed fetching polyfills', error)
    });


/* SCSS */
import "./css/valensina/valensina.scss";