//import picturefill from 'picturefill';
// @ts-ignore
//import * as lazySizes from 'lazysizes';
//import 'lazysizes/plugins/respimg/ls.respimg';

export default () => {
    console.log("Main");
    import(/* webpackChunkName: "burger" */"./burger").then(module => module.default());

    // @ts-ignore
    import(/* webpackChunkName: "lazysizes" */"lazysizes").then(import(/* webpackChunkName: "respimg" */'lazysizes/plugins/respimg/ls.respimg'));


    //const lazyloaded: HTMLCollectionOf<Element> = document.body.getElementsByClassName("lazyloaded");
    //if (lazyloaded && lazyloaded.length > 0) {
    // @ts-ignore
    //import(/* webpackChunkName: "picturefill" */"picturefill").then(module => module.default());
    //}

    const polaroidImages: NodeListOf<HTMLElement> | null = document.querySelectorAll(".polaroid");
    if (polaroidImages && polaroidImages.length > 0) {
        import("./polaroid").then(module => {
                polaroidImages.forEach((polaroidImage: HTMLElement) => {
                    module.default(polaroidImage);
                });
            }
        )
    }

    if (navigator.userAgent.indexOf('MSIE') !== -1
        || navigator.appVersion.indexOf('Trident/') > -1) {
        import('./polyfills').then(module => module.default());
    }

    const forms: NodeListOf<Element> = document.querySelectorAll(".ce_form form");
    if (forms && forms.length > 0) {
        import(/* webpackChunkName: "reCaptchaReplacement" */"./disableForm").then(module => {
            forms.forEach((form: any) => {
                module.default(form);
            })
        });
    }

    const fileUploaders: NodeListOf<Element> = document.querySelectorAll(".file-upload");
    if (fileUploaders && fileUploaders.length > 0) {
        import(/* webpackChunkName: "fileuploader" */"./fileuploader").then(module => {
            fileUploaders.forEach((wrapper: Element) => {
                module.default(wrapper);
            });
        });
    }

};